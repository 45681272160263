var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"52af639c1234c400a89f4357a0f4f62b5a0dd605"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import tokenService from './src/services/tokenService';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://e53d55e7c07449089e70e4cc853ebb14@o473467.ingest.sentry.io/5508370',
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        breadcrumb.data = {response: hint?.xhr.response, request: hint?.xhr.__sentry_xhr__};
      }
      return breadcrumb;
    },
    integrations: [
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });

  tokenService()
    .getAccessToken()
    .then((token) => {
      if (token !== undefined && token !== null) {
        Sentry.setUser({
          id: token.decodedToken?.userId,
          email: token.decodedToken?.email,
          firstName: token.decodedToken?.firstName,
          lastName: token.decodedToken?.lastName,
        });
      }
    });
}
